import { interpolate } from '@tb-core/helpers/interpolate';
import { RealObject } from '@tb-core/types';

import LoyaltyProgress from '@tb-core/components/styled/loyalty-progress-bar';

// @TODO: move into svg sprite once designs are finalized
import LevelIcon from '@tb-core/components/styled/loyalty-progress-bar/level-icon';

export interface ProgressProps {
    currentCustomerTierLevel: string;
    levelIconColors: RealObject;
    membershipLevelName: string;
    membershipConversionLimit: number;
    membershipLevelProgress: number;
    pointsAccumulated: number;
    progressPointsExceededLabel: string;
    progressPointsLabel: string;
    theme: RealObject;
    tierLevels: string[];
    unlockLabel: string;
    unlockedLabel: string;
}

// @TODO: move into helpers when rest of loyalty components are merged
const getNextLevel = (
    level: string,
    tiers: string[],
    unlockLabel: string,
    unlockedLabel: string
) => {
    const currentIndex = tiers.indexOf(level);
    const nextIndex = currentIndex + 1;

    const nextLevel = tiers[nextIndex]
        ? interpolate(unlockLabel, { tier: tiers[nextIndex] })
        : interpolate(unlockedLabel, { tier: tiers[currentIndex] });
    return nextLevel;
};

/*
 * The Progress component displays a graphical interface of a users current loyalty level
 * @TODO: Diablo is not currently supported. However, it has been included for testing purposes.
 * These props are based on template variables provided by the App team, as well as the Loyalty API
 */
const Progress = ({
    currentCustomerTierLevel,
    levelIconColors,
    membershipConversionLimit,
    membershipLevelName,
    membershipLevelProgress,
    pointsAccumulated,
    progressPointsExceededLabel,
    progressPointsLabel,
    theme,
    tierLevels,
    unlockLabel,
    unlockedLabel
}: ProgressProps) => {
    const currentLevelTitle = interpolate(currentCustomerTierLevel, {
        membershipLevelName
    });
    /*
     * @TODO: see if this can be normalized across all platforms. currently, the levels come in uppercase.
     * If this isn't changing, then any templates set up in Contentful should also use uppercase, and
     * this variable should be removed.
     */
    const levelNormalized = membershipLevelName.trim().toLowerCase() || '';
    const iconIndicator = (
        levelIconColors[levelNormalized] || []
    ).map((color: string, i: number) => (
        <LevelIcon key={i} fill={theme[color]} />
    ));
    const nextLevel = getNextLevel(
        membershipLevelName,
        tierLevels,
        unlockLabel,
        unlockedLabel
    );

    return (
        <LoyaltyProgress
            currentLevelTitle={currentLevelTitle}
            iconIndicator={iconIndicator}
            levelIconColors={levelIconColors}
            levelNormalized={levelNormalized}
            membershipConversionLimit={membershipConversionLimit}
            membershipLevelProgress={membershipLevelProgress}
            nextLevel={nextLevel}
            pointsAccumulated={pointsAccumulated}
            progressPointsExceededLabel={progressPointsExceededLabel}
            progressPointsLabel={progressPointsLabel}
            theme={theme}
        />
    );
};

export default Progress;
