import { useEffect, useState } from 'react';

import LoyaltyArch from '@tb-core/components/styled/loyalty-arch';

export interface ArchProps {
    assetPath: string;
    bgDoodleUnderlineImage: string;
    fill: string;
    pointsAccumulated: number;
    pointsConversionThreshold: number;
    pointsEarnedTowardFreeFood: string;
    pointsUntilNextFreeItem: string;
    radius: number;
    stroke: string;
    svgCircleDescription: string;
    svgCircleTitle: string;
    xAxisCoordinate: number;
    yAxisCoordinate: number;
}

export interface ArchStateProps {
    points: number;
    progressCurrent: number;
    strokeDashoffsetFg: number;
}

/*
 ** Renders an arch and it's numerical value.
 * Circumference respresents a set mathematical value. 100 represents 100%
 */

const Arch = ({
    assetPath,
    bgDoodleUnderlineImage,
    fill = 'none',
    pointsAccumulated = 0,
    pointsConversionThreshold,
    pointsEarnedTowardFreeFood,
    pointsUntilNextFreeItem,
    radius = 124,
    stroke = '#0e0022',
    svgCircleDescription = '',
    svgCircleTitle = '',
    xAxisCoordinate = 145,
    yAxisCoordinate = 145
}: ArchProps) => {
    const circumference = 2 * Math.PI * radius;

    const [state, setState] = useState<ArchStateProps>({
        points: 0,
        progressCurrent: 0,
        strokeDashoffsetFg: circumference * (1 - 0 / 100)
    });

    const progressPercent = pointsAccumulated / pointsConversionThreshold || 0;

    useEffect(() => {
        if (typeof pointsConversionThreshold !== 'undefined') {
            const progressMax =
                ((progressPercent > 1 ? 1 : progressPercent) * 100) / 2;
            setState({
                points: pointsAccumulated,
                progressCurrent: progressMax,
                strokeDashoffsetFg: circumference * (1 - progressMax / 100)
            });
        } else {
            setState({
                points: 0,
                progressCurrent: 0,
                strokeDashoffsetFg: circumference * (1 - 0 / 100)
            });
        }
    }, [pointsAccumulated, pointsConversionThreshold]);

    return (
        <LoyaltyArch
            assetPath={assetPath}
            bgDoodleUnderlineImage={bgDoodleUnderlineImage}
            fill={fill}
            pointsAccumulated={pointsAccumulated}
            pointsEarnedTowardFreeFood={pointsEarnedTowardFreeFood}
            pointsUntilNextFreeItem={pointsUntilNextFreeItem}
            radius={radius}
            stroke={stroke}
            strokeDasharrayBg={circumference / 2}
            strokeDasharrayFg={circumference}
            strokeDashoffsetBg={circumference}
            strokeDashoffsetFg={state.strokeDashoffsetFg}
            svgCircleDescription={svgCircleDescription}
            svgCircleTitle={svgCircleTitle}
            viewBox={`0 0 ${xAxisCoordinate * 2} ${yAxisCoordinate * 2}`}
            xAxisCoordinate={xAxisCoordinate}
            yAxisCoordinate={yAxisCoordinate}
        />
    );
};

export default Arch;
