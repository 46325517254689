import classNames from 'classnames';

import Line from '@tb-core/components/simple/line';
import { interpolate } from '@tb-core/helpers/interpolate';
import { JsxChildren, RealObject } from '@tb-core/types';

import styles from './styles.module.scss';

interface LoyaltyProgressProps {
    currentLevelTitle: string;
    iconIndicator: JsxChildren;
    levelIconColors: RealObject;
    levelNormalized: string;
    membershipConversionLimit: number;
    // fractional representing total progress out of a maximum 1 (0, .2, .5, 1, etc)
    membershipLevelProgress: number | null;
    nextLevel: string;
    pointsAccumulated: number;
    progressPointsExceededLabel: string;
    progressPointsLabel: string;
    theme: RealObject;
}

const renderPoints = (
    total: number,
    threshold: number,
    label: string,
    labelExceeded: string
) => {
    return total > threshold ? (
        <span className={styles['progress-point']}>
            {interpolate(labelExceeded, { total })}
        </span>
    ) : (
        <span className={styles['progress-point']}>
            {interpolate(label, { total })}
        </span>
    );
};

const LoyaltyProgressBar = ({
    currentLevelTitle,
    iconIndicator,
    levelNormalized,
    membershipConversionLimit,
    membershipLevelProgress = null,
    nextLevel,
    pointsAccumulated,
    progressPointsExceededLabel = '',
    progressPointsLabel = '',
    theme
}: LoyaltyProgressProps) => (
    <>
        {typeof membershipLevelProgress === 'number' && (
            <div className={styles['progress-drawer']}>
                <div className={styles['level-tier']}>
                    <span>{currentLevelTitle}</span>
                    <span className={styles['graphic-indicator']}>
                        {iconIndicator}
                    </span>
                </div>
                <div className={styles['level-progress-meter']}>
                    <svg
                        className={classNames(
                            styles['progress-bar'],
                            styles['progress-bar-bg']
                        )}
                    >
                        <Line
                            lineColor={theme.neutral}
                            xEnd="100%"
                            xStart="0"
                            yEnd="0"
                            yStart="0"
                        />
                    </svg>
                    <svg
                        className={classNames(
                            styles['progress-bar'],
                            styles['progress-bar-fg']
                        )}
                        style={{
                            width: `${membershipLevelProgress * 100}%`
                        }}
                    >
                        <Line
                            lineColor={theme[levelNormalized]}
                            xEnd="100%"
                            xStart="0"
                            yEnd="0"
                            yStart="0"
                        />
                    </svg>
                </div>
                <div className={styles['progress-stats']}>
                    <span className={styles['level-unlock']}>{nextLevel}</span>
                    {renderPoints(
                        pointsAccumulated,
                        membershipConversionLimit,
                        progressPointsLabel,
                        progressPointsExceededLabel
                    )}
                </div>
            </div>
        )}
    </>
);

export default LoyaltyProgressBar;
