import { useRef, useState } from 'react';

import getLoyaltyDashboard from '@tb-core/providers/get-loyalty-dashboard';
import { LoyaltyDashboardResponseBody } from '@tb-core/types';

const useLoyaltyDashboardApiContext = (
    active: boolean,
    isLoggedIn?: boolean
) => {
    const loyaltyDashboardRequested = useRef(false);

    const [dashboard, setDashboard] = useState<
        Partial<LoyaltyDashboardResponseBody>
    >({});

    const getLoyalty = async () => {
        if (active && isLoggedIn && !loyaltyDashboardRequested.current) {
            loyaltyDashboardRequested.current = true;
            setDashboard(await getLoyaltyDashboard());
        }
    };

    getLoyalty();

    return dashboard;
};

export default useLoyaltyDashboardApiContext;
