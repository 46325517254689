import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface ButtonProps {
    title: string;
    label: string;
}
export interface ButtonGroupProps {
    buttonGroup: ButtonProps[];
}

const ButtonGroup = ({
    buttonGroup: [primary, secondary]
}: ButtonGroupProps) => {
    const onClick = (cta: string) => {
        track('Clicked Rewards Dashboard', {
            cta
        });
    };

    return (
        <>
            <button
                onClick={() => onClick('My Rewards')}
                className={classNames([
                    styles.button,
                    styles['button-primary']
                ])}
                title={primary.title}
            >
                <span className={styles['gradient-text']}>{primary.label}</span>
            </button>
            {secondary && (
                <button
                    onClick={() => onClick('Challenges')}
                    title={secondary.title}
                    className={classNames([
                        styles.button,
                        styles['button-secondary']
                    ])}
                >
                    <span>{secondary.label}</span>
                </button>
            )}
        </>
    );
};

export default ButtonGroup;
