import { useEffect, useState } from 'react';

import Dashboard from '@tb-core/components/behavior/dashboard';
import LoadingSpinner from '@tb-core/components/composites/loading-spinner';
import { showImageErrorModal } from '@tb-core/components/container/image-error-modal';
import LoyaltyLoggedOut from '@tb-core/components/styled/loyalty-dashboard/logged-out';
import { getDocumentCookie } from '@tb-core/helpers/document-cookie';
import { assetPath } from '@tb-core/helpers/next-env';
import useLayoutActivityContext from '@tb-core/hooks/use-layout-activity-context';
import useLoyaltyDashboardApiContext from '@tb-core/hooks/use-loyalty-dashboard-api';
import useUserContext from '@tb-core/hooks/use-user-context';
import { LoyaltyDashboardTemplateProps } from '@tb-core/types';

import styles from './styles.module.scss';

interface LoyaltyContainerProps {
    activate: (update: boolean) => void;
    active: boolean;
    isStandalone?: boolean;
    loyaltyTemplate?: Partial<LoyaltyDashboardTemplateProps>;
}

const LoyaltyDashboardContainer = ({
    activate,
    active,
    isStandalone = false,
    loyaltyTemplate
}: LoyaltyContainerProps) => {
    const user = useUserContext();
    const { isLoggedIn } = user;
    const dashboard = useLoyaltyDashboardApiContext(active, isLoggedIn);
    const [isFirstTimeUser, setIsFirstTimeUser] = useState<string | null>(null);
    const [isFirstTimeUserLoyalty, setIsFirstTimeUserLoyalty] = useState<
        string | null
    >(null);

    const { updateModals } = useLayoutActivityContext();

    const updatedDash = {
        assetPath,
        ...dashboard
    };

    const openModalError = () => {
        activate(false);

        showImageErrorModal({
            buttonText: loyaltyTemplate?.errorModal?.buttonText,
            id: 'loyalty-error-modal',
            message: loyaltyTemplate?.errorModal?.message,
            title: loyaltyTemplate?.errorModal?.title,
            updateModals
        });
    };

    const postErrorMessage = (): void => {
        window?.parent?.postMessage('error-loyalty-dashboard-api');
    };

    useEffect(() => {
        setIsFirstTimeUser(getDocumentCookie('is_first_time_user'));
        setIsFirstTimeUserLoyalty(
            getDocumentCookie('is_first_time_user_loyalty')
        );
    }, []);

    useEffect(() => {
        if (dashboard.error) {
            isStandalone ? postErrorMessage() : openModalError();
        }
    }, [active, dashboard]);

    useEffect(() => {
        if (
            isLoggedIn &&
            isFirstTimeUser === 'true' &&
            loyaltyTemplate?.firstTimeUserRewardModal
        ) {
            updateModals({
                ['loyalty-first-time-user-reward-modal']: {}
            });
        }
    }, [isFirstTimeUser, isLoggedIn]);

    useEffect(() => {
        if (
            isLoggedIn &&
            isFirstTimeUserLoyalty === 'true' &&
            loyaltyTemplate?.firstTimeUserLegalModal
        ) {
            updateModals({
                ['loyalty-first-time-user-legal-modal']: {}
            });
        }
    }, [isFirstTimeUserLoyalty, isLoggedIn]);

    return (
        <>
            {typeof isLoggedIn === 'undefined' ||
            !loyaltyTemplate ||
            (isLoggedIn && !dashboard.membership_level_name?.length) ? (
                <LoadingSpinner className={styles.loader} />
            ) : (
                <>
                    {!isLoggedIn && (
                        <LoyaltyLoggedOut
                            assetPath={assetPath}
                            {...loyaltyTemplate.loggedOut}
                        />
                    )}
                    {isLoggedIn && (
                        <Dashboard
                            firstName={user.firstName}
                            isLoggedIn={isLoggedIn}
                            {...loyaltyTemplate}
                            {...updatedDash}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default LoyaltyDashboardContainer;
