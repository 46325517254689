export interface CircleProps {
    className: string;
    cy: number;
    cx: number;
    fill: string;
    r: number;
    stroke?: string;
    strokeDasharray: number;
    strokeDashoffset: number;
}

const Circle = ({
    className,
    cx = 145,
    cy = 145,
    fill = 'none',
    r = 124,
    stroke = '#fff',
    strokeDasharray = 0,
    strokeDashoffset = 0
}: CircleProps) => (
    <circle
        className={className}
        cx={cx}
        cy={cy}
        fill={fill}
        r={r}
        stroke={stroke}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
    />
);

export default Circle;
