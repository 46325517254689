import { Style } from '@tb-core/types/elements/style';

import styles from './styles.module.scss';

export interface LoyaltyGreetingProps {
    assetPath: string;
    customerGreetingDoodleImg: string;
    customerGreetingName: string;
    wisdomSignifier: string;
}

const LoyaltyGreeting = ({
    assetPath,
    customerGreetingDoodleImg,
    customerGreetingName,
    wisdomSignifier
}: LoyaltyGreetingProps) => {
    const style: Style = {
        '--background-image': `url(${assetPath + customerGreetingDoodleImg})`
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{customerGreetingName}</h2>
            <p
                className={styles.subtitle}
                dangerouslySetInnerHTML={{ __html: wisdomSignifier }}
                style={style}
            />
        </div>
    );
};

export default LoyaltyGreeting;
