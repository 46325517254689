import { devProxyResolve } from '@tb-core/helpers/browser/dev-proxy-resolve';
import { setLowTokenHeader } from '@tb-core/helpers/client/auth';
import Fetch from '@tb-core/helpers/fetch';
import { serverlessApiUrl } from '@tb-core/helpers/next-env';
import { loyaltyDashboardUrl } from '@tb-core/next/api/urls';
import { LoyaltyDashboardResponseBody } from '@tb-core/types';

const getLoyaltyDashboard = async () => {
    let res: Response;

    try {
        res = await Fetch({
            headers: await setLowTokenHeader(),
            host: serverlessApiUrl,
            url: devProxyResolve(loyaltyDashboardUrl)
        });
    } catch (e) {
        console.error('Loyalty Dashboard API GET request failed!', e);
        return { error: true } as LoyaltyDashboardResponseBody;
    }

    if (!res.ok) {
        return { error: true } as LoyaltyDashboardResponseBody;
    }

    return res.json() as Promise<LoyaltyDashboardResponseBody>;
};

export default getLoyaltyDashboard;
