import Circle from '@tb-core/components/simple/circle';
import Count from './count';

import styles from './styles.module.scss';
interface LoyaltyArchProps {
    assetPath: string;
    bgDoodleUnderlineImage: string;
    fill: string;
    pointsAccumulated: number;
    pointsEarnedTowardFreeFood: string;
    pointsUntilNextFreeItem: string;
    radius: number;
    ratio?: number;
    stroke?: string;
    strokeDasharrayBg: number;
    strokeDasharrayFg: number;
    strokeDashoffsetBg: number;
    strokeDashoffsetFg: number;
    svgCircleDescription: string;
    svgCircleTitle: string;
    viewBox: string;
    xAxisCoordinate: number;
    yAxisCoordinate: number;
}
/*
 ** Renders an arch and it's numerical value.
 * Circumference respresents a set mathematical value. 100 represents 100%
 */
const LoyaltyArch = ({
    assetPath,
    bgDoodleUnderlineImage,
    fill = 'none',
    pointsAccumulated = 0,
    pointsEarnedTowardFreeFood,
    pointsUntilNextFreeItem,
    radius = 124,
    ratio = 1,
    stroke = '#0e0022',
    strokeDasharrayBg,
    strokeDasharrayFg,
    strokeDashoffsetBg,
    strokeDashoffsetFg,
    svgCircleDescription,
    svgCircleTitle,
    viewBox,
    xAxisCoordinate = 145,
    yAxisCoordinate = 145
}: LoyaltyArchProps) => (
    <>
        <div className={styles.meter}>
            <svg
                className={styles['circle-container']}
                viewBox={viewBox}
                aria-labelledby="archTitle archDesc"
                role="img"
            >
                <title id="archTitle">{svgCircleTitle}</title>
                <desc id="archDesc">{svgCircleDescription}</desc>
                <Circle
                    className={styles['arch-bg']}
                    cx={xAxisCoordinate * ratio}
                    cy={yAxisCoordinate * ratio}
                    fill={fill}
                    r={radius * ratio}
                    stroke="#0e0022"
                    strokeDasharray={strokeDasharrayBg}
                    strokeDashoffset={strokeDashoffsetBg}
                />
                <Circle
                    className={styles['arch-fg']}
                    cx={xAxisCoordinate * ratio}
                    cy={yAxisCoordinate * ratio}
                    fill={fill}
                    r={radius * ratio}
                    stroke={stroke}
                    strokeDasharray={strokeDasharrayFg}
                    strokeDashoffset={strokeDashoffsetFg}
                />
            </svg>
        </div>
        <Count
            assetPath={assetPath}
            bgDoodleUnderlineImage={bgDoodleUnderlineImage}
            pointsAccumulated={pointsAccumulated}
            pointsEarnedTowardFreeFood={pointsEarnedTowardFreeFood}
            pointsUntilNextFreeItem={pointsUntilNextFreeItem}
        />
    </>
);

export default LoyaltyArch;
