import { Style } from '@tb-core/types/elements/style';

import styles from './styles.module.scss';

export interface CountProps {
    assetPath: string;
    bgDoodleUnderlineImage: string;
    pointsAccumulated: number;
    pointsEarnedTowardFreeFood: string;
    pointsUntilNextFreeItem: string;
}

/*
 * Displays the current quarter points scores, out of the maximum possible points.
 */
const Count = ({
    assetPath,
    bgDoodleUnderlineImage,
    pointsAccumulated,
    pointsEarnedTowardFreeFood,
    pointsUntilNextFreeItem
}: CountProps) => {
    const style: Style = {
        '--background-image': `url(${assetPath + bgDoodleUnderlineImage})`
    };

    return (
        <div className={styles['count-container']}>
            <div>
                <span className={styles['points-accumulated']}>
                    {pointsAccumulated}
                </span>
                <span className={styles['points-earned']}>
                    {pointsEarnedTowardFreeFood}
                </span>
            </div>
            <div
                className={styles['points-until']}
                dangerouslySetInnerHTML={{ __html: pointsUntilNextFreeItem }}
                style={style}
            />
        </div>
    );
};

export default Count;
