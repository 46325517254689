import { track } from '@amplitude/analytics-browser';

import { RealObject } from '@tb-core/types';

/**
 *
 * Takes data and pagePath from brandedCtaGroup
 * to create clicked module event for amplitude
 *
 */

const setModuleAmplitudeEvent = (data: RealObject, url: string) => {
    const ampProps = {
        cta: data.label,
        header_text: data.gaDataLayerConfig['Analytics-Value'].split('-')[0],
        module_type: data.gaDataLayerConfig['Analytics-Action'],
        page_path: url
    };

    track('Clicked Module', ampProps);
};

export default setModuleAmplitudeEvent;
