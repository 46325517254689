import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import { ThemableLinkButtonProps } from '@tb-core/components/styled/links/themable-link-button';
import setModuleAmplitudeEvent from '@tb-core/components/styled/modules/helpers/set-module-amplitude-event';

import styles from './styles.module.scss';

export interface CtaGroupProps {
    ctaGroup: ThemableLinkButtonProps[];
}

const CtaGroup = ({ ctaGroup: [primary, secondary] }: CtaGroupProps) => {
    const pagePath = useRouter().asPath;

    const trackCtaClick = useCallback(() => handleClick(primary || secondary), [
        primary,
        secondary
    ]);

    const handleClick = (module: ThemableLinkButtonProps) => {
        setModuleAmplitudeEvent(module, pagePath);
    };

    return (
        <>
            <SafeAnchorWithGaEvent
                className={classNames([
                    styles.button,
                    styles['button-primary']
                ])}
                gaDataLayerConfig={primary.gaDataLayerConfig}
                href={primary.url}
                onClick={trackCtaClick}
            >
                <span className={styles['gradient-text']}>{primary.label}</span>
            </SafeAnchorWithGaEvent>
            <SafeAnchorWithGaEvent
                className={classNames([
                    styles.button,
                    styles['button-secondary']
                ])}
                gaDataLayerConfig={secondary.gaDataLayerConfig}
                href={secondary.url}
                onClick={trackCtaClick}
            >
                {secondary.label}
            </SafeAnchorWithGaEvent>
        </>
    );
};

export default CtaGroup;
