import { Props } from '@tb-core/types';

// @TODO: move this SVG into sprite when approved.
const LevelIcon = ({ fill = '#cecece' }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="27"
        viewBox="0 0 19 27"
    >
        <path
            fill={fill}
            d="M18.51 16.947a.53.53 0 0 0-.47-.06l-1.08.42a8.15 8.15 0 0 0-.29-5c-.86-2-2.8-3.82-4.42-5.32l-.42-.39a6.23 6.23 0 0 1-1.75-2.14 6 6 0 0 1-.48-2.92.5.5 0 0 0-.85-.4 7.58 7.58 0 0 0-1.77 2.61 8.79 8.79 0 0 0-.52 4 9.49 9.49 0 0 0 .28 1.71 9.96 9.96 0 0 1 .34 2c.08 1.44-.1 1.77-.3 2a1.489 1.489 0 0 1-1.29.46 1.56 1.56 0 0 1-1-.66 2.53 2.53 0 0 1-.41-1.29 5.66 5.66 0 0 1 0-1.59.5.5 0 0 0-.24-.52.52.52 0 0 0-.57 0 9 9 0 0 0-1.82 2.1 9.9 9.9 0 0 0-1.21 7.32A9.64 9.64 0 0 0 9 26.357h.4a9.4 9.4 0 0 0 9.32-9 .52.52 0 0 0-.21-.41zm-6.12 7.24a3.76 3.76 0 0 1-2.59 1.12h-.32a3.087 3.087 0 0 1-2.59-1.37 3.84 3.84 0 0 1-.48-2.87 4.45 4.45 0 0 1 1.14-2.31.26.26 0 0 1 .29-.06.27.27 0 0 1 .14.26 1.3 1.3 0 0 0 .25.94.86.86 0 0 0 .52.36.9.9 0 0 0 .72-.31 1.64 1.64 0 0 0 .38-1 4.64 4.64 0 0 0-.32-2.41.24.24 0 0 1 .06-.29.27.27 0 0 1 .3 0 7.19 7.19 0 0 1 3.29 4.48 4 4 0 0 1-.79 3.46z"
        />
    </svg>
);

export default LevelIcon;
