import classNames from 'classnames';
import { useEffect } from 'react';

import Arch, { ArchProps } from '@tb-core/components/behavior/arch';
import Progress, { ProgressProps } from '@tb-core/components/behavior/progress';
import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import AppIcons from '@tb-core/components/styled/app-icons';
import Image from '@tb-core/components/styled/image';
import ButtonGroup from '@tb-core/components/styled/loyalty-dashboard/button-group';
import CtaGroup from '@tb-core/components/styled/loyalty-dashboard/cta-group';
import Greeting, {
    LoyaltyGreetingProps
} from '@tb-core/components/styled/loyalty-greeting';
import { updateDataLayer } from '@tb-core/helpers/analytics/google';
import { assetPath } from '@tb-core/helpers/next-env';
import { RealObject } from '@tb-core/types';

import styles from './styles.module.scss';

interface LoyaltyDashboardProps {
    archProps: Omit<ArchProps, 'assetPath'>;
    buttonGroup: any;
    ctaGroup: any;
    greetingProps: Omit<LoyaltyGreetingProps, 'assetPath'>;
    infoProps: RealObject;
    loggedInDashBoardBg: string;
    loggedInPaperTexture: string;
    progressProps: ProgressProps;
}

const LoyaltyDashboard = ({
    archProps,
    buttonGroup,
    ctaGroup,
    greetingProps,
    infoProps,
    loggedInDashBoardBg,
    loggedInPaperTexture,
    progressProps
}: LoyaltyDashboardProps) => {
    useEffect(() => {
        updateDataLayer({
            'Analytics-Action': 'Rewards',
            'Analytics-Value': 'Dashboard',
            _event: 'gtm.elementVisibility',
            modalName: 'rewards-dashboard'
        });
    }, []);

    return (
        <div className={styles.dashboard}>
            <Image
                className={styles['loyalty-bg-image']}
                src={loggedInDashBoardBg}
            />
            <div className={styles.top}>
                <Greeting assetPath={assetPath} {...greetingProps} />
                <Arch assetPath={assetPath} {...archProps} />
                <FlexGridLayout
                    align="stretch"
                    className={classNames(
                        styles['mobile-flex'],
                        styles.buttons
                    )}
                    direction="col"
                    justify="around"
                >
                    <CtaGroup {...ctaGroup} />
                </FlexGridLayout>
                <FlexGridLayout
                    align="stretch"
                    className={classNames(styles.flex, styles.buttons)}
                    justify="between"
                >
                    <ButtonGroup {...buttonGroup} />
                </FlexGridLayout>
                <p
                    className={classNames(
                        styles.desktop,
                        styles['stores-subtitle']
                    )}
                >
                    {infoProps.downloadAppCopy}
                </p>
                <FlexGridLayout
                    align="stretch"
                    className={styles.stores}
                    justify="center"
                >
                    <AppIcons />
                </FlexGridLayout>
                <Image
                    className={styles['bg-paper-image']}
                    src={loggedInPaperTexture}
                />
            </div>
            <div className={styles.bottom}>
                <Progress {...progressProps} />
                <div className={styles['footer-links']}>
                    <SafeAnchorWithGaEvent
                        className={styles['footer-link']}
                        gaDataLayerConfig={{
                            'Analytics-Action': 'Rewards > Logged In',
                            'Analytics-Value': `Rewards Dashboard >  ${infoProps.rewardsLearnMore.title}`
                        }}
                        href={infoProps.rewardsLearnMore.url}
                        target="_top"
                    >
                        {infoProps.rewardsLearnMore.title}
                    </SafeAnchorWithGaEvent>
                    <SafeAnchorWithGaEvent
                        className={styles['footer-link']}
                        gaDataLayerConfig={{
                            'Analytics-Action': 'Rewards > Logged In',
                            'Analytics-Value': `Rewards Dashboard >  ${infoProps.rewardsFaq.title}`
                        }}
                        href={infoProps.rewardsFaq.url}
                        target="_top"
                    >
                        {infoProps.rewardsFaq.title}
                    </SafeAnchorWithGaEvent>
                </div>
            </div>
        </div>
    );
};

export default LoyaltyDashboard;
