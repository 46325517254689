interface LineProps {
    lineColor: string;
    xEnd: string;
    xStart: string;
    yEnd: string;
    yStart: string;
}

const Line = ({
    lineColor,
    xEnd,
    xStart,
    yEnd,
    yStart
}: LineProps) => (
    <line x1={xStart} y1={yStart} x2={xEnd} y2={yEnd} stroke={lineColor} />
);

export default Line;
