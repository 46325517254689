import classNames from 'classnames';
import React, { CSSProperties } from 'react';

import { DivProps } from '@tb-core/types/elements/standard.d';

import styles from './styles.module.scss';

export interface FlexGridLayoutProps extends DivProps {
    align?: 'center' | 'end' | 'start' | 'stretch';
    direction?: 'col' | 'row';
    justify?: 'around' | 'between' | 'center' | 'end' | 'start';
    style?: CSSProperties | undefined;
    wrap?: boolean;
}

const FlexGridLayout = ({
    align = 'center',
    className,
    direction = 'row',
    justify = 'center',
    wrap = false,
    ...props
}: FlexGridLayoutProps) => (
    <div
        {...props}
        className={classNames(
            styles['flex-grid-layout'],
            styles[direction],
            {
                [styles.wrap]: wrap
            },
            styles[`align-${align}`],
            styles[`justify-${justify}`],
            className
        )}
    />
);

export default FlexGridLayout;
