import classNames from 'classnames';

import SafeAnchorWithGaEvent from '@tb-core/components/behavior/safe-anchor/with-ga-event';
import Image from '@tb-core/components/styled/image';

import styles from './styles.module.scss';

interface AppIconProps {
    appStoreUrl?: string;
    className?: string;
    playStoreUrl?: string;
}

const AppIcons = ({
    appStoreUrl = 'https://apps.apple.com/us/app/taco-bell/id497387361',
    className,
    playStoreUrl = 'https://play.google.com/store/apps/details?id=com.tacobell.ordering'
}: AppIconProps) => (
    <div className={classNames(className, styles.icons)}>
        <SafeAnchorWithGaEvent
            gaDataLayerConfig={{
                'Analytics-Action': 'Get the App',
                'Analytics-Value': 'App Store'
            }}
            href={appStoreUrl}
            target="_blank"
        >
            <Image
                src="/_static/web/images/loyalty/apple-store@3x.png"
                alt="Apple App Store icon."
            />
        </SafeAnchorWithGaEvent>
        <SafeAnchorWithGaEvent
            gaDataLayerConfig={{
                'Analytics-Action': 'Get the App',
                'Analytics-Value': 'Google Play'
            }}
            href={playStoreUrl}
            target="_blank"
        >
            <Image
                src="/_static/web/images/loyalty/google@3x.png"
                alt="Google Player Store icon."
            />
        </SafeAnchorWithGaEvent>
    </div>
);

export default AppIcons;
