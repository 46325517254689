import { RealObject } from '@tb-core/types';

export const getCurrentQuarter = (pointsAccumulated: number) => {
    if (pointsAccumulated >= 50 && pointsAccumulated <= 99) {
        return 2;
    } else if (pointsAccumulated >= 100 && pointsAccumulated <= 149) {
        return 3;
    } else if (pointsAccumulated >= 150) {
        return 4;
    }
    return 1;
};

export const getWisdomSignifier = (results: RealObject) =>
    results[Math.floor(Math.random() * results.length)];
